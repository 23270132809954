import React from 'react';

import BANNER from './BANNER2x.jpg';
import sarah1 from './sarah1.jpg';
// import apaLeagueInfo from './apaLeagueInfo.jpg';
// import bcaLeagueInfo from './bcaLeagueInfo.jpg';
import bcaRules from './bcaRules.jpg';
import hustle from './hustle.jpg';
// import chaosDaylight from './chaosDaylight.jpg';
// import orchidLogo from './orchidThaiLogo.png';
import orchidCombo from './orchidCombo.jpg';
// import chaosBilliardsSchedule from './chaosBilliardsSchedule.jpg';
import chaosClothes from './chaosClothes.jpg';
import topPlayers from './topPlayers.jpg';
import aimingToWin from './aimingToWin.jpg';
// import orchidThaiTall from './orchidThaiTall.jpg';
import nineFoot from './nineFoot.jpg';
import secondRoomB from './secondRoomB.jpg';

import './App.css';
function App() {
    if (0) {
        return AppOld()
    }
    return (
    <div className="App">
        <header className="App-header">
            <img src={BANNER} className="banner" alt="chaos billiard" />
        </header>
        <div className="bodyClass" >
        <div className="MajorMessage">
<p>
Our current location on Division Street will be closed beginning Dec 4, 2022.
</p>
 <p>
      We are working hard to find a better location! Please look forward to news in early 2023. We love our Central Oregon Pool Community!
</p>
<p>
If you need to contact us please send us an email at:<br />
<a className="emailLink" href="mailto:info@chaosbilliards.com?subject=ContactFromWeb&body=Hello Chaos Billiards Lounge, ">info@chaosbilliards.com</a>
        <br />
</p>
<p>
For information about current APA leagues please contact <br />Steve or Kelly at 541-388-5188<br />
<a className="App-link" href="https://www.facebook.com/AmericanPoolplayersAssociationCascade">APA Facebook Page</a>
</p>

<p>
For information about current BCA leagues please contact <br />Karla Bagley-Tias<br />
<a className="App-link" href="https://www.facebook.com/groups/287964211329139/">BCA Facebook Page</a>
</p>

<p>
<img src={topPlayers} className="banner" alt="topPlayers" />
</p>
<p>
<img src={sarah1} className="banner" alt="sarah kennedy" />
</p>
<p>
<img src={aimingToWin} className="banner" alt="aimingToWin" />
</p>
<img src={nineFoot} className="banner" alt="nineFoot" /><br />
          <img src={secondRoomB} className="banner" alt="secondRoomB" /><br />
            <img src={bcaRules} className="banner" alt="bcaRules" /><br />
<p>
<img src={hustle} className="banner" alt="hustle" /><br />
<img src={chaosClothes} className="banner" alt="chaosclothesPic" /><br />

          Copyright © 2022 Chaos Billiards Lounge - All Rights Reserved.
</p>

      </div>

        </div>
    </div>
    )
}

function AppOld() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={BANNER} className="banner" alt="chaos billiard" />
        <div className="MajorBullet"><br />
          THE HIGHEST QUALITY POOL HALL EXPERIENCE IN CENTRAL OREGON!
          <br />
          All Diamond Tables!<br /><br />
        </div>      

        {/* <img src={chaosDaylight} className="banner" alt="chaosDaylight" /> */}
        <img src={topPlayers} className="banner" alt="topPlayers" />
      </header>

      <div className="bodyClass" >
        <div className="MinorBullet"><br />
          Play and improve with the most talented and friendly pool players in the region!<br /><br />
          1900 NE Division Street, Bend, OR
        </div>
          <div className="MinorBullet"><br />
            Late August and September mark the beginning of new fall league seasons for both BCA and APA leagues. See schedule below for leagues and tournament info!<br /><br />
           <br />
          </div>
 
        <img src={sarah1} className="banner" alt="sarah kennedy" />
        <div>
        <br />
          <p className="MajorBullet">Weekly Schedule: Open Tables begin 4PM every day<br />
          </p>
          <ul className="bulletList">
            <li className="liclass">Monday: APA League 9 Ball (TBD 7PM). 3 open tables</li>
            <li className="liclass">Tuesday: BCA League 8 ball play 7PM start. Limited open tables during league.</li>
            <li className="liclass">Wednesday: BCA League 9 ball play 7PM start. Limited open tables during league.</li>
            <li className="liclass">Thursday: APA League 8 ball play 7PM start. Limited open tables during league.</li>
            <li className="liclass">Friday: Open Play all night with periodic tournaments.</li>
            <li className="liclass">Saturday: Open Play all night. Open tournament 3rd Saturday of every month at 11AM.</li>
            <li className="liclass">Sunday: Open Tables + APA League 8 ball and 9 ball double jeopardy leagues. Play 4PM start.</li>
          </ul>
          <p className="MajorBullet">8,9,10-ball Rotating Tourney<br />
            <ul className="bulletList">
              <li className="liclass"> 3rd Saturday each month. 11AM. <br />
              </li>
            </ul>
          </p>

          <a
          className="App-link"
          href="https://www.facebook.com/AmericanPoolplayersAssociationCascade/"
          target="_blank" rel="noreferrer"
          >APA League Information on Facebook
          </a>
           <br /> <br />
          <a
          className="App-link"
          href="https://www.facebook.com/chaosbilliards"
          target="_blank" rel="noreferrer"
          >Chaos Billiards Information on Facebook
          </a>
                
        <br /><br />
          Chaos Billiards Location:<br /><br />
            <address className="MinorBullet">1900 NE Division Street<br />
              Bend, Oregon 97701 <br />
              Phone: (541) 797-6978
              <br /><br />
              Email:<br />
          <a className="emailLink" href="mailto:info@chaosbilliards.com?subject=ContactFromWeb&body=Hello Chaos Billiards Lounge, ">info@chaosbilliards.com</a>
          <br />
          </address>
        </div>
        <div>
            <p>We are Next door to Orchid Thai!<br /><br />
            <img src={orchidCombo} className="banner" alt="orchidCombo" />
            <br />
            </p>
        </div>
        <div>
          CHAOS BILLIARDS LOUNGE
          <p className="MinorBullet">
            <ul className="bulletList">
              <li className="liclass">Cocktails Beer Food</li>
              <li className="liclass">Cocktails and Beer served in our main pool hall</li>
              <li className="liclass">Family room welcomes kids </li>
              <li className="liclass">Our own food selections</li>
              <li className="liclass">Orchid Thai Menu available too !</li>
            </ul>
          </p>
        </div>

        <img src={aimingToWin} className="banner" alt="aimingToWin" />

        <p>
          <br />
          ALL OF OUR TABLES ARE TOURNAMENT QUALITY DIAMOND TABLES<br /><br />
          Hourly table rates and memberships available!<br /><br />
          9 ft Diamond table in our family friendly room<br /><br />
          
          <div>
          <img src={nineFoot} className="banner" alt="nineFoot" /><br />
          <img src={secondRoomB} className="banner" alt="secondRoomB" /><br />
            <img src={bcaRules} className="banner" alt="bcaRules" /><br />
            {/* <img src={apaLeagueInfo} className="banner" alt="apaLeagueInfo" /><br /> */}
            {/* <img src={bcaLeagueInfo} className="banner" alt="bcaLeagueInfo" /> */}
            <br />
          </div>
          <br />
          Chaos Billiards Lounge<br />
          1900 Northeast Division Street, Bend, OR 97701<br />
          <br />
          (541) 797-6978<br />
          <a className="emailLink" href="mailto:info@chaosbilliards.com?subject=ContactFromWeb&body=Hello Chaos Billiards Lounge, ">info@chaosbilliards.com</a>
          <br />
          <br />
          <img src={hustle} className="banner" alt="hustle" /><br />
          <img src={chaosClothes} className="banner" alt="chaosclothesPic" /><br />

          Copyright © 2022 Chaos Billiards Lounge - All Rights Reserved.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
      </div>
    </div>
  );
}

export default App;
